@import "./assets/fonts/iconly.min.css";

@tailwind base;
@tailwind components;
@tailwind utilities;

#startDate::-webkit-calendar-picker-indicator {
  margin-left: -28px;
  font-size: small;
}
#endDate::-webkit-calendar-picker-indicator {
  margin-left: -28px;
  font-size: small;
}
.tooltipp {
  @apply invisible absolute;
}

.has-tooltipp:hover .tooltipp {
  @apply visible z-50;
}
.subMenu {
  @apply invisible;
}

.upMenu:hover .subMenu {
  @apply visible;
}

#root {
  white-space: pre-line;
}

table thead {
  /* head takes the height it requires, 
  and it's not scaled when table is resized */
  flex: 0 0 auto;
  width: 100%;
}
table tbody {
  /* body takes all the remaining available space */
  flex: 1 1 auto;
  display: block;
  overflow-y: scroll;
}
table tbody tr {
  width: 100%;
}
table thead,
table tbody tr {
  display: table;
  table-layout: fixed;
}

html,
body {
  margin: 0;
  height: 100%;
  width: 100%;
  font-family: "Montserrat", "menu-icons";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.react-calendar__month-view__days__day {
  font-size: small !important;
}
.react-calendar__navigation__prev-button {
  background: none !important;
  border-radius: 100% !important;
  border: 1px solid #bde9d3 !important;
}
.react-calendar__navigation__next-button {
  background: none !important;
  border-radius: 100% !important;
  border: 1px solid #bde9d3 !important;
}
.react-calendar__tile--active {
  background: none !important;
  color: #588a70 !important;
  border-radius: 100% !important;
  border-style: solid !important;
  border-width: 2px !important;
  border-bottom-color: #8eee7f !important;
  border-left-color: #56c6da !important;
  border-right-color: #5271c7 !important;
  border-top-color: #b04ed6 !important;
}

abbr[data-bs-original-title],
abbr[title] {
  cursor: pointer !important;
  text-decoration: none !important;
}
.react-calendar__tile--now {
  background: #eef1ee !important;
  border-radius: 100% !important;
}
.react-calendar__tile {
  min-height: 3rem;
}

.animationMD {
  width: 10rem;
  height: 10rem;
  -webkit-animation: Device-wait-animate 1s infinite; /* Safari 4+ */
  -moz-animation: Device-wait-animate 1s infinite; /* Fx 5+ */
  -o-animation: Device-wait-animate 1s infinite; /* Opera 12+ */
  animation: Device-wait-animate 1s infinite; /* IE 10+, Fx 29+ */
}

@keyframes Device-wait-animate {
  0%,
  49% {
    border: 3px solid #e7771ca8;
    box-shadow: inset 0 0 2px 3px rgba(233, 95, 41, 0.2);
  }
  50%,
  100% {
    border: 3px solid rgba(209, 182, 63, 0.6);
    box-shadow: inset 0 0 2px 3px rgba(209, 148, 17, 0.2);
  }
}
.animationSM {
  width: 4rem;
  height: 4rem;
  -webkit-animation: Device-wait-animate2 1s infinite; /* Safari 4+ */
  -moz-animation: Device-wait-animate2 1s infinite; /* Fx 5+ */
  -o-animation: Device-wait-animate2 1s infinite; /* Opera 12+ */
  animation: Device-wait-animate2 1s infinite; /* IE 10+, Fx 29+ */
}

@keyframes Device-wait-animate2 {
  0%,
  49% {
    border: 3px solid #d4413c7e;
  }
  50%,
  100% {
    border: 3px solid rgba(217, 230, 104, 0.6);
  }
}
option:checked {
  background: #f0f2f3 !important;
  color: rgb(82, 79, 79) !important;
}

.form-control {
  padding-top: 1.2rem !important;
  padding-bottom: 1.2rem !important;
  border-bottom-right-radius: 2rem !important;
  border-top-right-radius: 2rem !important;
  border-top-left-radius: 2rem !important;
  border-bottom-left-radius: 2rem !important;
  border-color: #e5e9ec !important;
  border-width: 0.175rem !important;
  width: 100% !important;
  background: transparent !important;
  color: #9e9c9a !important;
}
.flag-dropdown {
  border-top-left-radius: 2rem !important;
  border-bottom-left-radius: 2rem !important;
  background: transparent !important;
}

.selected-flag {
  border-top-left-radius: 2rem !important;
  border-bottom-left-radius: 2rem !important;
  background: rgba(236, 233, 233, 0.479) !important;
}

@layer components {
  .scrollbarCard::-webkit-scrollbar {
    width: 10px;
    height: 10px;
    display: none;
  }

  .scrollbarCard::-webkit-scrollbar-track {
    border-radius: 100vh;
  }

  .scrollbarCard::-webkit-scrollbar-thumb {
    background: #d4dadb;
    border-radius: 100vh;
    border: 4px solid #f6f7ed;
  }

  .scrollbarCard::-webkit-scrollbar-thumb:hover {
    background: #eaecec;
  }
}

@layer components {
  .scrollbarContent::-webkit-scrollbar {
    display: none;
  }
}

.scrollVisible:hover .scrollbarCard::-webkit-scrollbar {
  display: block;
}

@layer components {
  .scrollbarY::-webkit-scrollbar {
    width: 2px;
    height: 2px;
  }
  .scrollbarY::-webkit-scrollbar-track {
    border-radius: 100vh;
    background: #f0eeee;
  }
  .scrollbarY::-webkit-scrollbar-thumb {
    background: #eaecec;
    border-radius: 100vh;
    border: 1px solid #dbdbdb;
  }
  .scrollbarY::-webkit-scrollbar-thumb:hover {
    background: #ededed;
  }
}
@layer components {
  .scrollbarY2::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }
  .scrollbarY2::-webkit-scrollbar-track {
    border-radius: 100vh;
    background: #eaecec;
    margin-bottom: 0.8rem;
  }
  .scrollbarY2::-webkit-scrollbar-thumb {
    background: #d4d3d3;
    border-radius: 100vh;
    border: 2px solid #dbdbdb;
  }
  .scrollbarY2::-webkit-scrollbar-thumb:hover {
    background: #ededed;
  }
}
@layer components {
  .transparentScroll::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }
  .transparentScroll::-webkit-scrollbar-track {
    background: transparent;
  }
  .transparentScroll::-webkit-scrollbar-thumb {
    background: transparent;
  }
  .transparentScroll::-webkit-scrollbar-thumb:hover {
    background: transparent;
  }
}
